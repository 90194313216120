const state = () => {
  return {
    menu: [
      {
        icon: 'DatabaseIcon',
        pageName: 'side-menu-dashboard',
        title: 'Dictionaries',
        subMenu: [
          {
            icon: '',
            pageName: 'Country',
            permission: 'DICT_READ',
            title: 'Countries'
          },
          {
            icon: '',
            pageName: 'SectorCategory',
            permission: 'DICT_READ',
            title: 'Sectors'
          },
          {
            icon: '',
            pageName: 'Sector',
            permission: 'DICT_READ',
            title: 'Sub-sectors'
          },
          {
            icon: '',
            pageName: 'OriginOfFunding',
            permission: 'DICT_READ',
            title: 'Origins of funding'
          },
          {
            icon: '',
            pageName: 'Nationality',
            permission: 'DICT_READ',
            title: 'Nationalities'
          },
          {
            icon: '',
            pageName: 'Language',
            permission: 'DICT_READ',
            title: 'Languages'
          }
        ]
      },
      {
        icon: 'UsersIcon',
        pageName: 'side-menu-dashboard',
        title: 'Experts',
        subMenu: [
          {
            icon: '',
            pageName: 'Experts',
            permission: 'EXP_READ',
            title: 'Experts'
          },
          {
            icon: '',
            pageName: 'ExpertsNotActual',
            permission: 'EXP_OUTDATED',
            title: 'Outdated experts'
          }
        ]
      },
      {
        icon: 'FolderPlusIcon',
        pageName: 'References',
        permission: 'REF_READ',
        title: 'References'
      },
      {
        icon: 'LockIcon',
        pageName: 'accessControl',
        permission: 'SUPER_ADMIN',
        title: 'Access control'
      },
      {
        icon: 'UserIcon',
        pageName: 'side-menu-dashboard',
        title: 'Users',
        subMenu: [
          {
            icon: '',
            pageName: 'userroles',
            permission: 'USR_READ',
            title: 'User roles'
          },
          {
            icon: '',
            pageName: 'users',
            permission: 'USR_ROLE_READ',
            title: 'Users'
          }
        ]
      }
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
