import $api from '@/http'

async function getList(pageNo, pageSize, filterModel) {
  return await $api.get('expert/list', {
    params: {
      pageNo: pageNo,
      pageSize: pageSize,
      id: filterModel.id,
      lastName: filterModel.lastName,
      firstName: filterModel.firstName,
      email: filterModel.email,
      phone: filterModel.phone,
      sectorId: filterModel.sectorId,
      subSectorId: filterModel.subSectorId,
      countryId: filterModel.countryId,
      stajStart: filterModel.stajStart,
      stajEnd: filterModel.stajEnd,
      eduDegree: filterModel.eduDegree,
      eduSpecialization: filterModel.eduSpecialization,
      keyword: filterModel.keyword
    }
  })
}

async function create(datas) {
  return await $api.post('expert/create', datas)
}

async function update(datas) {
  return await $api.patch('expert/update', datas)
}

async function getById(id) {
  return await $api.get('expert/getById/' + id)
}

async function saveFiles(id, formData) {
  return await $api.patch(`expert/saveFiles/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

async function getExpertFiles(expertId) {
  return await $api.get('expert/files', {
    params: {
      expertId
    }
  })
}

async function deleteFile(id, expertFileId) {
  return await $api.delete(`expert/deleteFile`, {
    params: {
      id, expertFileId
    }
  })
}

async function downloadCV(expertId, number, isEn) {
  return await $api.get('expert/downloadCV', {
    params: {
      expertId,
      number,
      isEn
    },
    responseType: 'arraybuffer'
  })
}

/**
 * MAILING APIS
 */
async function getListNotActualExperts(pageNo, pageSize, filterModel) {
  return await $api.get('expert/list/notActual', {
    params: {
      pageNo: pageNo,
      pageSize: pageSize,
      lastName: filterModel.lastName,
      firstName: filterModel.firstName,
      email: filterModel.email,
      phone: filterModel.phone,
      subsector: filterModel.subsector,
      keyword: filterModel.keyword
    }
  })
}

async function resendEmails() {
  return await $api.get('expert/resendEmails')
}

export default {
  getList,
  create,
  update,
  getById,
  saveFiles,
  getExpertFiles,
  deleteFile,
  downloadCV,

  getListNotActualExperts,
  resendEmails
}
